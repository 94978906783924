import React, { useState, useEffect } from 'react';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import './global.css';
import { graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { Image, Transformation } from 'cloudinary-react';
import {
  Grid,
  Modal,
  ModalContent,
  Button,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ReactMarkdown from 'react-markdown';
import scrollTo from 'gatsby-plugin-smoothscroll';
import SEO from '../components/seo';
import { IndexPageQuery, SitePageContext } from '../../graphql-types';

interface AppProps {
  data: IndexPageQuery;
  location: Location & { state: { englishChosen: boolean; atTheTop: boolean } };
  pageContext: SitePageContext;
}

export const query = graphql`
  query IndexPage {
    strapiAb {
      english_content
      french_content
    }
    allStrapiWork {
      edges {
        node {
          title
          slug
          id
          published
          image_cover {
            childImageSharp {
              fluid(cropFocus: CENTER, maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          images {
            caption
            url
            provider_metadata {
              public_id
            }
          }
          french
          english
          date
          order
          typology
          typology_french
        }
      }
    }
    allStrapiMenu {
      edges {
        node {
          english_content
          french_content
          id
          english_name
          french_name
          order
          published
          menu_width
        }
      }
    }
  }
`;

function waitForElementToDisplay(
  selector,
  callback,
  checkFrequencyInMs,
  timeoutInMs,
) {
  const startTimeInMs = Date.now();
  (function loopSearch() {
    if (document.querySelector(selector) != null) {
      callback();
    } else {
      setTimeout(() => {
        if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) return;
        loopSearch();
      }, checkFrequencyInMs);
    }
  })();
}

const App: React.FC<AppProps> = ({
  data,
  location: { state },
  pageContext,
}) => {
  function LinkRenderer({ href, children }) {
    return (
      <Link
        onClick={(event) => {
          event.stopPropagation();
        }}
        state={{ englishChosen }}
        to={href}
      >
        {children}
      </Link>
    );
  }

  const [englishChosen, setEnglishChosen] = useState(
    state?.englishChosen === undefined ? false : state.englishChosen,
  );

  const [carouselNode, setCarouselNode] = useState(
    data?.allStrapiWork?.edges?.filter(
      ({ node: { id: nodeId } }) => nodeId === pageContext?.id,
    ),
  );

  const [menuModalContent, setMenuModalContent] = useState(null);

  const [carouselText, setCarouselText] = useState({
    english: pageContext?.english,
    french: pageContext?.french,
    title: pageContext?.title,
    date: pageContext?.date?.toString(),
  });

  const [openMenu, setOpenMenu] = useState(null);

  const [hoveredTypology, setHoveredTypology] = useState({
    typology: '',
    typology_french: '',
    name: '',
    date: '',
  });

  const [hoveredAB, setHoveredAB] = useState(false);

  const [hoveredImg, setHoveredImg] = useState(null);

  const [yOffsetTop, setYOffsetTop] = useState(false);

  const [mobile, setMobile] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    globalThis.innerWidth > 1000 ? setMobile(false) : setMobile(true);
    globalThis.addEventListener('resize', () => {
      globalThis.innerWidth > 1000 ? setMobile(false) : setMobile(true);
    });
  }, []);

  useEffect(() => {
    waitForElementToDisplay(
      'section',
      () => {
        const { parentElement } = document.querySelector('section');
        parentElement.style.height = '100vh';
        parentElement.style.maxHeight = '100vh';
        parentElement.style.top = '0';
      },
      10,
      3000,
    );
  });

  useEffect(() => {
    if (state !== null && !state?.atTheTop) {
      setYOffsetTop(true);
    }
  }, []);

  useEffect(() => {
    const scrollFromTop = () => {
      const gatsbyFocusWrapper = document.querySelector(
        '#gatsby-focus-wrapper',
      );
      const scrollTop = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop,
        gatsbyFocusWrapper.scrollTop,
      );
      if (scrollTop > 0) {
        setYOffsetTop(true);
      } else {
        setYOffsetTop(false);
      }
    };
    const gatsbyFocusWrapper = document.querySelector('#gatsby-focus-wrapper');
    gatsbyFocusWrapper.addEventListener('scroll', scrollFromTop);
    return () =>
      gatsbyFocusWrapper.removeEventListener('scroll', scrollFromTop);
  }, []);

  useEffect(() => {
    if (pageContext.id) {
      scrollTo(`#${pageContext.id}`);
      setTimeout(() => onOpen(), 500);
    }
  }, []);

  useEffect(() => {
    waitForElementToDisplay(
      'table',
      () => {
        const table = document.querySelector('table');

        const trs = document.querySelectorAll('tr');
        const textNodes = [...trs].filter(
          (tr) => tr.firstChild.firstChild.nodeName === '#text',
        );

        table.addEventListener('mouseover', () => {
          textNodes.forEach((textNode) => {
            textNode.style.opacity = '0.2';
          });
        });

        table.addEventListener('mouseleave', () => {
          textNodes.forEach((textNode) => {
            textNode.style.opacity = '1';
          });
        });
      },
      10,
      3000,
    );
  });

  return (
    <div id="app">
      <SEO title="ALEXIS BERTRAND" />

      <MouseTooltip visible={hoveredImg && !mobile} offsetX={0} offsetY={0}>
        <p className="tooltip-text">
          {hoveredTypology && hoveredTypology?.name?.toLocaleUpperCase()}
        </p>
        <p className="tooltip-text">
          {englishChosen
            ? hoveredTypology && hoveredTypology?.typology?.toLocaleUpperCase()
            : hoveredTypology &&
              hoveredTypology?.typology_french?.toLocaleUpperCase()}
        </p>
        <p className="tooltip-text">
          {hoveredTypology && hoveredTypology?.date}
        </p>
      </MouseTooltip>

      <div id="app-start" />

      <Flex className="menu-wrapper">
        {hoveredAB ? (
          <div
            onMouseOver={() => {
              setHoveredAB(true);
            }}
            onMouseLeave={() => {
              setHoveredAB(false);
            }}
            className="button-menu-wrapper"
          >
            <Button
              onClick={() => {
                setOpenMenu('ab-title');
                setMenuModalContent(
                  englishChosen
                    ? data.strapiAb.english_content
                    : data.strapiAb.french_content,
                );
                setCarouselNode(null);
                setCarouselText({
                  english: '',
                  french: '',
                  title: '',
                  date: '',
                });
                onOpen();
              }}
              className="button-menu"
              style={{
                opacity: isOpen && openMenu !== 'ab-title' ? 0.1 : 1,
              }}
            >
              CONTACT
            </Button>
          </div>
        ) : (
          <div
            onMouseOver={() => {
              setHoveredAB(true);
            }}
            onMouseLeave={() => {
              setHoveredAB(false);
            }}
            className="button-menu-wrapper"
          >
            <Button
              onClick={() => {
                setOpenMenu('ab-title');
                setMenuModalContent(
                  englishChosen
                    ? data.strapiAb.english_content
                    : data.strapiAb.french_content,
                );
                setCarouselNode(null);
                setCarouselText({
                  english: '',
                  french: '',
                  title: '',
                  date: '',
                });
                onOpen();
              }}
              className="button-menu"
              style={{
                opacity: isOpen && openMenu !== 'ab-title' ? 0.1 : 1,
              }}
            >
              ALEXIS BERTRAND
            </Button>
          </div>
        )}

        {data?.allStrapiMenu?.edges
          ?.filter(({ node }) => node.published)
          ?.sort(({ node: { order: a } }, { node: { order: b } }) => a - b)
          ?.map(({ node }) => (
            <div key={`menu-${node.id}`} className="button-menu-wrapper">
              <Button
                onClick={() => {
                  setOpenMenu(node.id);
                  setMenuModalContent(
                    englishChosen ? node.english_content : node.french_content,
                  );
                  setCarouselNode(null);
                  setCarouselText({
                    english: '',
                    french: '',
                    title: '',
                    date: '',
                  });
                  onOpen();
                }}
                className="button-menu"
                style={{
                  opacity: isOpen && openMenu !== node.id ? 0.1 : 1,
                }}
              >
                {englishChosen ? node.english_name : node.french_name}
              </Button>
            </div>
          ))}
        <div className="button-menu-wrapper">
          <Button
            className="button-menu"
            style={{
              opacity: isOpen ? 0.1 : 1,
            }}
            onClick={() =>
              setEnglishChosen((currentLanguage) => !currentLanguage)
            }
          >
            {englishChosen ? 'FRANÇAIS' : 'ENGLISH'}
          </Button>
        </div>
      </Flex>

      <Grid
        className="content-wrapper"
        gap="9vw"
        style={{
          marginTop: `${!mobile ? '0' : '40px'}`,
        }}
      >
        {data?.allStrapiWork?.edges
          ?.filter(({ node }) => node.published)
          ?.sort(({ node: { order: a } }, { node: { order: b } }) => b - a)
          ?.map(
            ({
              node: {
                id,
                image_cover,
                english,
                french,
                title,
                date,
                typology,
                typology_french,
              },
            }) => (
              <div key={`card-div-carousel-${id}`}>
                <div
                  key={`carousel_${id}`}
                  id={id}
                  className="card-div"
                  role="button"
                  onMouseOver={() => {
                    setHoveredTypology({
                      typology,
                      typology_french,
                      name: title,
                      date: date.toString(),
                    });
                  }}
                  onClick={() => {
                    setHoveredImg(false);
                    setMenuModalContent(null);
                    setOpenMenu(null);
                    setCarouselText({
                      english,
                      french,
                      title,
                      date: date.toString(),
                    });
                    setCarouselNode(
                      data?.allStrapiWork?.edges?.filter(
                        ({ node: { id: nodeId } }) => nodeId === id,
                      ),
                    );
                    onOpen();
                  }}
                >
                  <div
                    className="img-wrapper"
                    onMouseOver={() => setHoveredImg(true)}
                    onMouseLeave={() => setHoveredImg(false)}
                  >
                    <Img
                      className="card-img"
                      fluid={image_cover?.childImageSharp?.fluid}
                      key={`image_${id}`}
                      style={{
                        opacity: isOpen ? 0.1 : 1,
                        filter: isOpen ? 'grayscale(30%)' : null,
                      }}
                    />
                  </div>
                </div>
                <div
                  className="mobile-infos"
                  style={{
                    opacity: isOpen ? 0.1 : 1,
                  }}
                >
                  <p className="mobile-infos-para">{title}</p>
                  <p className="mobile-infos-para">
                    {englishChosen ? typology : typology_french}
                  </p>
                  <p className="mobile-infos-para">{date}</p>
                </div>
              </div>
            ),
          )}
        <Modal isOpen={isOpen} scrollBehavior="inside" blockScrollOnMount>
          <ModalContent
            className="modal-content-wrapper"
            style={{
              maxWidth: !mobile ? '45vw' : '100vw',
              paddingTop: !mobile ? '6vw' : '25vh',
            }}
          >
            <div
              className="modal-menu-content-wrapper"
              onClick={() => {
                onClose();
                navigate('/', {
                  state: {
                    englishChosen: englishChosen || null,
                    atTheTop: false,
                  },
                });
              }}
            >
              <div className="menu-content">
                <div className="menu-content-margin">
                  <ReactMarkdown
                    escapeHtml={false}
                    source={menuModalContent}
                    renderers={{ link: LinkRenderer }}
                  />
                </div>
              </div>
            </div>
            <CarouselProvider
              infinite
              naturalSlideWidth={1080}
              naturalSlideHeight={720}
              touchEnabled={false}
              dragEnabled
              dragStep={0}
              totalSlides={carouselNode?.[0]?.node?.images.length}
            >
              <Slider>
                {carouselNode?.[0]?.node?.images.map(
                  ({ url, provider_metadata: { public_id } }, i) => (
                    <Slide key={`${url}`} index={i}>
                      <ButtonNext
                        className="carousel-button-next"
                        onClick={(event) => {
                          event.preventDefault();
                          event.isDefaultPrevented();
                          event.stopPropagation();
                        }}
                      />
                      <Image
                        cloudName="dwep510px"
                        publicId={`${public_id}.jpg`}
                        useRootPath="true"
                        clientHints="true"
                      >
                        <Transformation width="auto" dpr="auto" crop="scale" />
                      </Image>
                    </Slide>
                  ),
                )}
              </Slider>
              <div className="carousel-content-wrapper-texte">
                <Text
                  className="modal-carousel-text"
                  style={{
                    paddingTop: carouselText.title ? '1rem' : '0',
                  }}
                >
                  {carouselText.title}
                </Text>
                <ReactMarkdown
                  className="modal-carousel-text"
                  escapeHtml={false}
                  source={
                    englishChosen ? carouselText.english : carouselText.french
                  }
                />
                <Text className="modal-carousel-text">{carouselText.date}</Text>
              </div>
            </CarouselProvider>
          </ModalContent>
        </Modal>
      </Grid>
      <div
        style={{
          bottom: 15,
        }}
        id="go-to-top-wrapper"
      >
        <button
          type="button"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => scrollTo('#app-start')}
          id="go-to-top-btn"
        >
          <div
            id="btn-sizer"
            style={{
              display: yOffsetTop ? 'flex' : 'none',
              opacity: isOpen ? 0.1 : 1,
            }}
          >
            {englishChosen ? 'TOP' : 'HAUT'}
          </div>
        </button>
      </div>
      <div>
        {data?.allStrapiMenu?.edges
          ?.filter(({ node }) => node.english_name === 'CREDITS')
          .map(({ node }) => (
            <div key={`credits-${node.id}`}>
              <div
                className="mobile-margin"
                style={{
                  paddingLeft: mobile ? '10px' : '9vw',
                  paddingRight: mobile ? '10px' : '9vw',
                }}
              >
                <ReactMarkdown
                  escapeHtml={false}
                  source={
                    englishChosen ? node.english_content : node.french_content
                  }
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default App;
